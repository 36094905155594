<template>
  <b-modal :active.sync="isCardModalActive" :width="610" scroll="keep" @close="$emit('close')">
    <div class="order-modal order-modal_buy">
      <h1 class="order-modal__title">Buy SMS</h1>
      <ul class="order-modal__order-list">
        <li class="order-modal__order-list-item">
          <span class="order-modal__order-info">Sender:</span>

          <span class="order-value">
            <!-- <p class="order-info__value--other" v-if="data.detected_service.name == 'Other'">Other</p> -->
            <img
              class="order-card__service-icon"
              v-if="data.detected_service.icon"
              :src="data.detected_service.icon"
            >
            <span>{{ data.detected_service.name }}</span>
          </span>
        </li>
        <li class="order-modal__order-list-item">
          <span class="order-modal__order-info">Encrypt text:</span>
          <span class="order-value">{{`${data.sms_text}`.slice(0, 30)}}</span>
        </li>
        <li class="order-modal__order-list-item">
          <span class="order-modal__order-info">Receive at:</span>
          <span class="order-value">{{data.received_at}}</span>
        </li>
        <li class="order-modal__order-list-item order-modal__order-list-item_total">
          <span class="order-modal__order-info">Price</span>
          <span class="order-value price">€{{data.price}}</span>
        </li>
      </ul>
      <button class="order-modal__button" @click="buy()">Buy and close order</button>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { OrdersService } from "../../services/orders.services";

export default {
  props: {
    isCardModalActive: {
      type: Boolean,
      default: false
    },

    data: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({}),

  computed: {
    ...mapGetters(["socetMessage"]),
    ...mapState(["socket"])
  },

  watch: {
    socetMessage() {}
  },

  methods: {
    async buy() {
      try {
        const response = await OrdersService.buySms(this.data.id);

        this.$emit("successfullyPurchased", response);
      } catch (e) {
        this.$toasted.show(`sms not bought!`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 2000,
          className: "danger-notification"
        });
      }

      // if (response) this.$emit("successfullyPurchased", response);
      // else
      //   this.$toasted.show(`sms not bought!`, {
      //     theme: "toasted-primary",
      //     position: "top-right",
      //     duration: 2000,
      //     className: "danger-notification"
      //   });

      // this.$emit("successfullyPurchased", {
      //   id: 55,
      //   sender: "37258663956",
      //   received_at: "2020-01-05 14:58",
      //   sms_text: "Hi",
      //   detected_service: { id: 208, name: "Other", icon: null },
      //   was_bought: true,
      //   price: "3.00"
      // });

      this.$emit("close");

      // this.$router.push("history");
    }
  }
};
</script>
