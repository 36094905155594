import { ApiService } from "./api.service";
// const BASE_URL = "https://api.plazalab.club/";
import { BASE_URL } from "@/utils/constants";

class OrdersError extends Error {
  constructor(message, errorCode, statusText) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
    this.errorText = statusText;
  }
}

const OrdersService = {
  getOrders: async function(limit, offset) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/orders/`,
      params: {
        limit: limit,
        offset: offset
      }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new OrdersError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  getOrderSMS: async function(id) {
    const requestData = {
      method: "get",
      url: `${BASE_URL}api/storage/orders/${id}/sms_list/`
      // params: {
      //   limit: limit,
      //   offset: offset
      // }
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      throw new OrdersError(
        error.response.data,
        error.response.status,
        error.response.statusText
      );
    }
  },

  buySms: async function(id) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/storage/sms/${id}/buy/`
    };

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.response.data);

      return false;

      // throw new OrdersError(
      //   error.response.data,
      //   error.response.status,
      //   error.response.statusText
      // );
    }
  },

  connectNumber: async function(order_id) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/storage/orders/connect/`,
      data: {
        order_id
      }
    };

    console.log(requestData);

    try {
      const response = await ApiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.response.data);

      return error.response.data;

      // throw new OrdersError(
      //   error.response.data,
      //   error.response.status,
      //   error.response.statusText
      // );
    }
  },

  closeNumber: async function(order_id) {
    const requestData = {
      method: "post",
      url: `${BASE_URL}api/storage/orders/cancel/`,
      data: {
        order_id
      }
    };

    console.log(requestData);

    try {
      const response = await ApiService.customRequest(requestData);

      // console.log(response.data);

      return response.data;
    } catch (error) {
      console.log(error);
      console.log(error.response.data);

      return error.response.data;
    }
  }

  /* getService: async function () {
      const requestData = {
        method: 'get',
        url: `${BASE_URL}api/storage/services/`
      }
  
      try {
        const response = await ApiService.customRequest(requestData)
  
        return response.data
      } catch (error) {
        throw new OrdersError(error.response.data, error.response.status, error.response.statusText)
      }
    },
  
    getCountries: async function () {
      const requestData = {
        method: 'get',
        url: `${BASE_URL}api/storage/countries/`
      }
  
      try {
        const response = await ApiService.customRequest(requestData)
  
        return response.data
      } catch (error) {
        throw new OrdersError(error.response.data, error.response.status, error.response.statusText)
      }
    },
  
    getNumbers: async function (services, country) {
      const requestData = {
        method: 'get',
        url: `${BASE_URL}api/storage/numbers/${services.id}/${country.id}/`
      }
  
      try {
        const response = await ApiService.customRequest(requestData)
  
        return response.data
      } catch (error) {
        throw new OrdersError(error.response.data, error.response.status, error.response.statusText)
      }
    },
  
    buySms: async function (data) {
      const requestData = {
        method: 'post',
        url: `${BASE_URL}api/storage/orders/new/`,
        data: data
      }
  
      try {
        const response = await ApiService.customRequest(requestData)
  
        return response.data
      } catch (error) {
        throw new OrdersError(error.response.data, error.response.status, error.response.statusText)
      }
    } */
};

export default OrdersService;

export { OrdersService, OrdersError };
