<template>
  <div class="countdown-timer">
    <!-- <pre>{{this.progress}}</pre> -->
    <svg
      class="countdown-timer__circle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 90"
      width="90"
      height="90"
    >
      <rect x="0" y="0" width="90" height="90" fill="transparent"></rect>
      <circle
        cx="150"
        cy="45"
        r="40"
        stroke="#111014"
        stroke-width="8"
        fill="none"
        transform="rotate(-95,97.5,93)"
      ></circle>
      <circle
        cx="150"
        cy="45"
        r="40"
        stroke="#fff"
        :stroke-dasharray="dasharray"
        stroke-offset="600"
        stroke-width="8"
        fill="none"
        transform="rotate(-95,97.5,93)"
      ></circle>
      <text
        x="23"
        y="50"
        fill="#fff"
        font-size="18"
      >{{ minute | formatTime }}:{{ second | formatTime }}</text>
    </svg>
  </div>
</template>

<script>
export default {
  circumference: 2 * Math.PI * 40,
  data: () => ({
    state: "stopped",
    minute: 0,
    second: 0,
    progress: 0,
    timeInSeconds: 0,

    startTimer: null,
    sendTimer: null
  }),
  props: ["time", "defaultTimer", "letStart"],
  computed: {
    dasharray() {
      return this.progress + " " + this.$options.circumference;
    }
  },
  watch: {
    sendTimer() {
      this.start();
    },
    letStart() {
      this.start();
    },
    second() {
      this.$emit("change", this.second + this.minute * 60);
    },
    time(value) {
      if (value == 0) this.timeInSeconds = value;
    }
  },
  methods: {
    updateTime() {
      this.timeInSeconds = Number(this.minute * 60) + Number(this.second);
    },
    initComponent() {
      // this.timeInSeconds = Number(this.time) * 60;
      this.timeInSeconds = parseInt(this.time);

      this.minute = Math.floor(this.timeInSeconds / 60);
      this.second = this.timeInSeconds - this.minute * 60;
      // this.progress = this.$options.circumference;
    },
    start() {
      // this.timeInSeconds = Number(this.time) * 60
      // this.minute = this.time
      // this.progress = this.$options.circumference;
      this.state = "started";
      if (this.progress == 0) {
        // this.progress = this.$options.circumference;
      }

      // this.progress = this.$options.circumference * 0.5;

      this.progress =
        this.$options.circumference * (this.timeInSeconds / this.defaultTimer);

      // let delta = this.$options.circumference / this.timeInSeconds;
      // if (this.progress - delta < delta / 2) {
      //   this.progress = 0;
      // } else {
      //   this.progress -= delta;
      // }

      this._tick();
      this.interval = setInterval(this._tick, 1000);
    },
    pause() {
      this.state = "paused";
      clearInterval(this.interval);
    },
    stop() {
      this.state = "stopped";
      clearInterval(this.interval);
      this.minute = 0;
      this.second = 0;
      this.progress = 0;
    },
    _tick: function() {
      //if second is 0 and minute is 0, clear the interval
      if (this.timeInSeconds == 0) {
        this.stop();

        return;
      }

      this.timeInSeconds -= 1;
      this.progress =
        this.$options.circumference * (this.timeInSeconds / this.defaultTimer);

      this.minute = Math.floor(this.timeInSeconds / 60);
      this.second = this.timeInSeconds - this.minute * 60;

      // //update progress
      // let delta = this.$options.circumference / this.timeInSeconds;
      // if (this.progress - delta < delta / 2) {
      //   this.progress = 0;
      // } else {
      //   this.progress -= delta;
      // }
      // //if second is not 0, just decrement second
      // if (this.second !== 0) {
      //   this.second--;
      //   return;
      // }
      // //if second is 0 and minute is not 0, decrement minute and set second to 59
      // if (this.minute !== 0) {
      //   this.minute--;
      //   this.second = 59;
      // }
    }
  },
  filters: {
    formatTime: function(value) {
      if (value >= 10) {
        return value;
      }
      return "0" + value;
    }
  },
  mounted() {
    this.initComponent();

    if (this.letStart === "3") {
      this.sendTimer = 3;
      this.initComponent();
    }

    if (this.letStart === "1") {
      this.startTimer = 1;
    }
  },
  beforeDestroy() {
    this.stop();
  }
};
</script>

<style lang="scss" scoped>
.countdown-timer {
  margin: 15px 0 10px;

  &__circle {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }
}

@media all and (max-width: 1024px) {
  .countdown-timer {
    margin: 40px 0 30px;
  }
}

@media all and (max-width: 767px) {
  .countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 22px auto 26px;
  }
}
</style>
