<template>
  <div class="active-orders-page" ref="content">
    <div :class="[{'active-orders-page__body': count > 3}]">
      <OrderCard
        v-for="(order, index) in ordersList"
        :key="index"
        :order-info="order"
        :numberConnected="conectedSucces"
        @connect="connectNumber"
      />
    </div>
  </div>
</template>

<script>
import iconMixins from "../mixins/decorIcons";
import OrderCard from "@/components/order/card/OrderCard";
import { OrdersService } from "../services/orders.services";
import { TokenService } from "../services/storage.service";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    ordersList: [
      // {
      //   id: 149,
      //   service: {
      //     id: 94,
      //     name: "HappyPancakeiin",
      //     icon:
      //       "https://api.smsplaza.io/media/service_icons/HappyPancakeiin.png"
      //   },
      //   number: "(+372) 53887555",
      //   status: "connected",
      //   error_reason: "",
      //   flag: "ee",
      //   created_at: "2020-01-05 14:35",
      //   timer: "271"
      // }
    ],
    isCardModalActive: false,
    limit: 3,
    offset: 0,
    count: 0,

    // Web Socket
    conectedSucces: []
  }),
  components: {
    OrderCard
  },
  computed: {
    ...mapGetters(["socetMessage"]),
    ...mapState(["socket"])
  },
  watch: {
    socetMessage() {
      if (this.socetMessage.event === "order.number_connected") {
        this.conectedSucces.push(this.socket.socket.message.order_id);
        this.$toasted.show(`Number connected`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 2000,
          className: "success-notification"
        });
      }

      if (
        this.socetMessage.event === "order.completed" &&
        this.socetMessage.status === "canceled"
      ) {
        this.$toasted.show(
          `Order ${this.socket.socket.message.status} because ${
            this.socket.socket.message.error_reason
          }`,
          {
            theme: "toasted-primary",
            position: "top-right",
            duration: 2000,
            className: "success-notification"
          }
        );
      } else if (
        this.socetMessage.event === "order.completed" &&
        this.socetMessage.status === "completed"
      ) {
        this.$toasted.show(`Order ${this.socket.socket.message.status}`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 2000,
          className: "success-notification"
        });
      }
    }
  },
  methods: {
    ...mapActions(["sendMessage"]),
    async loadOrders() {
      try {
        const response = await OrdersService.getOrders(this.limit, this.offset);

        this.count = response.count;
        if (this.ordersList.length === 0) {
          this.ordersList = response.results;
        } else {
          this.ordersList = this.ordersList.concat(response.results);
        }
      } catch (error) {
        /* this.errorMessage = error.response
        this.showErrorMsg() */
        throw error;
      }
    },
    connectNumber(data) {
      // let order = {
      //   action: "order.connect_number",
      //   order_id: data
      // };
      // let dataSend = JSON.stringify(order);
      // this.sendMessage(dataSend);
    }
  },
  async mounted() {
    this.loadOrders();

    // let data = {
    //   event: "sms.new",
    //   order_id: 14,
    //   sms: {
    //     id: 32,
    //     received_at: "2019-12-03 20:12",
    //     sms_text: "Tes* ot*er *hat*app key mes*age*tes* 21* te*t",
    //     detected_service: {
    //       id: 12,
    //       name: "Facebook",
    //       icon: "/media/service_icons/facebook-logo.png"
    //     },
    //     was_bought: false,
    //     price: "3.00"
    //   }
    // };
    let scrollElem = this.$refs.content;

    scrollElem.addEventListener("scroll", e => {
      if (
        scrollElem.scrollTop + scrollElem.clientHeight + 10 >=
        scrollElem.scrollHeight
      ) {
        if (this.offset <= this.count) {
          this.offset = this.offset + 3;
          this.loadOrders();
        }
      }
    });
  }
};
</script>
