<template>
  <!-- :class="{'order-card_error': order.errorHelper, 'order-card_ordered': step === 6}" -->
  <div class="order-card" v-if="step">
    <!-- <pre @click="timer = 0">{{timer}}</pre> -->
    <!-- <pre>{{orderInfo}}</pre> -->
    <!-- <pre>{{filteredMessages}}</pre> -->
    <!-- <pre @click="handleSend(orderInfo.id)">{{step}}</pre> -->

    <header class="order-card__header">
      <div class="order-card__header-column order-card__header-column_date">
        <span class="order-card__header-title">Time</span>
        <span class="order-card__header-value">{{ orderInfo.created_at.split(" ")[1] }}</span>
      </div>
      <div class="order-card__header-column order-card__header-column_number">
        <span class="order-card__header-title">Number</span>
        <span class="order-card__header-value order-card__header-value_number">
          <span :class="`flag-icon flag-icon-${orderInfo.flag}`"></span>
          {{ orderInfo.number }}
          <button
            class="clipboard"
            @click="doCopy(orderInfo.number)"
          >
            <b-icon pack="far" icon="copy" size="is-small"></b-icon>
          </button>
        </span>
      </div>
      <div class="order-card__header-column order-card__header-column_service">
        <span class="order-card__header-title">Service</span>
        <div class="order-card__header-value">
          <img
            class="order-card__service-icon"
            v-if="orderInfo.service.icon"
            :src="orderInfo.service.icon"
          >
          <span>{{ orderInfo.service.name }}</span>
        </div>
      </div>
      <div class="order-card__header-column order-card__header-column_status">
        <span class="order-card__header-title">Status</span>

        <span v-if="status" class="order-card__header-value active">
          <b-icon pack="fas" icon="lock-open" size="is-small"></b-icon>
          <span class="order-tablet">Order is active</span>
        </span>

        <span v-else class="order-card__header-value close">
          <b-icon class="close__icon" pack="fas" icon="lock" size="is-small"></b-icon>
          <span class="order-tablet">Order is closed</span>
        </span>
      </div>

      <div
        class="order-card__header-column order-card__header-column_timer order-card__circle-timer-controller"
      >
        <template
          v-if="(step === 'sms' || step === 'closed'  || step === 'completed') && timer > 0"
        >
          <AppCircleTimer
            class="order-card__circle-timer"
            :style="timer < 0 && {display: 'block'}"
            :time="timer"
            :defaultTimer="defaultTimer"
            :letStart="timerStart"
            @change="changeTimer"
          />

          <div
            class="order-card__cancel-button"
            :style="timer < 0 && {display: 'none'}"
            @click="handleCancel()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="90" height="90">
              <rect x="0" y="0" width="90" height="90" fill="transparent"></rect>
              <circle
                cx="150"
                cy="45"
                r="40"
                stroke="#ff3860"
                stroke-width="8"
                fill="none"
                transform="rotate(-95,97.5,93)"
              ></circle>

              <text x="23" y="50" fill="#ff3860" font-size="14">Cancel</text>
            </svg>
          </div>
        </template>

        <div class="order-card__header-column_timer-hide" v-else></div>
      </div>
    </header>

    <div class="order-card__body">
      <div v-if="step === 'await_activate'" class="order-card-step">
        <h1 class="order-card-step__titile order-card-step__titile_upper">warning!</h1>

        <p class="order-card-step__descr">
          Press the button, of only you are sure that you are ready to send SMS.
          <br>You will have 5 minutes to receive SMS
        </p>

        <div class="order-card__circle-timer-controller">
          <AppCircleTimer
            class="order-card__circle-timer"
            :style="timer < 0 && {display: 'block'}"
            :time="timer"
            :defaultTimer="defaultTimer"
            :letStart="timerStart"
            @change="changeTimer"
          />

          <div
            class="order-card__cancel-button"
            :style="timer < 0 && {display: 'none'}"
            @click="handleCancel()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="90" height="90">
              <rect x="0" y="0" width="90" height="90" fill="transparent"></rect>
              <circle
                cx="150"
                cy="45"
                r="40"
                stroke="#ff3860"
                stroke-width="8"
                fill="none"
                transform="rotate(-95,97.5,93)"
              ></circle>

              <text x="23" y="50" fill="#ff3860" font-size="14">Cancel</text>
            </svg>
          </div>
        </div>

        <button
          :class="['order-card-step__btn', !isReadyToConnect && 'order-card-step__btn--disabled']"
          :disabled="!isReadyToConnect"
          @click="connectionNumber(orderInfo)"
        >{{isReadyToConnect ? "Start connection number" : "Line's busy, please wait.."}}</button>
      </div>

      <div v-if="step === 'activate'" class="order-card-step">
        <!-- <h1 class="order-card-step__titile">Please wait</h1>
        <p class="order-card-step__descr">Number is connecting. Please wait..</p>-->

        <h1 class="order-card-step__titile">Number is connecting. Please wait..</h1>

        <button class="order-card-step__loader"></button>
      </div>

      <!--  <div v-if="step === 3" class="order-card-step">
          <h1 class="order-card-step__titile">Number is connected</h1>
          <p class="order-card-step__descr">Press the button to start receiving SMS</p>
          <timer :time="1" />
          <button class="order-card-step__btn">Start</button>
      </div>-->

      <div v-if="step === 'await_sms'" class="order-card-step">
        <!-- <p class="order-card-step__descr">You can send sms, now</p> -->
        <h1 class="order-card-step__titile">Send a SMS. We are waiting..</h1>

        <div class="order-card__circle-timer-controller">
          <AppCircleTimer
            class="order-card__circle-timer"
            :style="timer < 0 && {display: 'block'}"
            :time="timer"
            :defaultTimer="defaultTimer"
            :letStart="timerStart"
            @change="changeTimer"
          />

          <div
            class="order-card__cancel-button"
            :style="timer < 0 && {display: 'none'}"
            @click="handleCancel()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90" width="90" height="90">
              <rect x="0" y="0" width="90" height="90" fill="transparent"></rect>
              <circle
                cx="150"
                cy="45"
                r="40"
                stroke="#ff3860"
                stroke-width="8"
                fill="none"
                transform="rotate(-95,97.5,93)"
              ></circle>

              <text x="23" y="50" fill="#ff3860" font-size="14">Cancel</text>
            </svg>
          </div>
        </div>
      </div>

      <div v-if="step === 'error'" class="order-card-step">
        <b-icon
          pack="fas"
          icon="exclamation-triangle"
          size="is-large"
          class="order-card-step__error-icon"
        ></b-icon>
        <h1 class="order-card-step__titile order-card-step__titile_error">Error</h1>
        <p class="order-card-step__descr">{{errorsText[error]}}</p>
        <!-- <p class="order-card-step__descr">Number is not connected</p> -->
      </div>

      <div
        v-if="orderInfo.status && step === 'sms'"
        class="order-card-step"
        :class="{'order-card-step_order': step === 'sms'}"
      >
        <div class="order-info" v-for="(item, index) in messages" :key="index">
          <div class="order-info__column order-info__column_time">
            <span class="order-card__header-title">Time</span>
            <span class="order-info__value">{{item.received_at.split(" ")[1]}}</span>
          </div>

          <div class="order-info__column order-info__column_text">
            <span class="order-card__header-title">Text</span>

            <span
              class="order-info__value order-info__value--wrong-service"
              v-if="item.is_wrong_service"
            >
              {{`You got SMS from the "${
              item.detected_service.name
              }", but you chose "${
              orderInfo.service.name
              }". Create a new order with the correct service.`}}
            </span>

            <span class="order-info__value" v-else>{{item.sms_text}}</span>
          </div>

          <div class="order-info__column order-info__column_service">
            <span class="order-card__header-title">Service</span>
            <!-- <span class="order-info__value">{{orderInfo.number}}</span> -->
            <span class="order-info__value">
              <!-- <p class="order-info__value--other" v-if="item.detected_service.name == 'Other'">Other</p> -->
              <img
                class="order-card__service-icon"
                v-if="item.detected_service.icon"
                :src="item.detected_service.icon"
              >
              {{ item.detected_service.name }}
            </span>
          </div>

          <div class="order-info__column order-info__column_action">
            <span class="order-card__header-title">Action</span>

            <b-icon
              v-if="item.is_wrong_service"
              pack="fas"
              icon="exclamation-triangle"
              size="is-medium"
              class="order-card-step__error-icon"
            ></b-icon>

            <button
              class="order-info__buy"
              v-else-if="!item.was_bought && !buyedSms"
              @click="handleBuy(index)"
            >Buy SMS</button>

            <span class="order-info__value" v-else>{{item.was_bought ? "Buyed" : "—"}}</span>
          </div>
        </div>
      </div>

      <!-- <div v-if="step === 'closed'" class="order-card-step">
        <h2 class="order-card__status-order">Order closed</h2>
        <p
          class="order-card__descr-order"
        >there`s gonna be a different text here. There`s gonna be a different text here</div>
      </div>-->

      <div v-if="step === 'completed'" class="order-card-step">
        <h2 class="order-card__status-order order-card__status-order--completed">Order is Completed</h2>
        <p
          class="order-card__descr-order"
        >there`s gonna be a different text here. There`s gonna be a different text here</p>
      </div>
    </div>

    <OrderModalBuy
      v-if="step === 'sms' && !buyedSms"
      :isCardModalActive="isCardModalActive"
      :data="messages[selectedSms]"
      @successfullyPurchased="handleSuccessBuy"
      @close="isCardModalActive = false"
    />

    <OrderModalConfirm
      :orderId="orderInfo.id"
      :isCardModalActive="isComfirmModalActive"
      @successfullyPurchased="handleSuccessBuy"
      @close="isComfirmModalActive = false"
    />

    <!-- isCardModalActive -->
  </div>
</template>

<script>
import AppCircleTimer from "@/components/app/root/AppCircleTimer";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import { OrdersService } from "@/services/orders.services";

import OrderModalBuy from "@/components/order/OrderModalBuy";
import OrderModalConfirm from "@/components/order/OrderModalConfirm";

import { pushNotification } from "@/utils/pushNotification";

import moment from "moment";

export default {
  name: "Order-card",

  props: ["order-info", "numberConnected"],
  // props: ["numberConnected"],

  components: {
    AppCircleTimer,

    OrderModalBuy,
    OrderModalConfirm
  },

  data: () => ({
    status: false,
    step: null,
    timer: 0,
    defaultTimer: 0,
    timerStart: null,
    isCardModalActive: true,
    isComfirmModalActive: false,
    messages: [],
    selectedSms: null,
    error: null,
    isReadyToConnect: false,
    buyedSms: false,

    errorsText: {
      reserved_time_out: `You didn't press "Start connection number" button in 30 minutes.`,
      sms_not_coming: "You didn't get any SMS in 5 minutes.",
      not_connected:
        "The number is not connected. Try again or take another number.",
      did_not_buy_sms: "You did not buy SMS within 5 minutes.",
      cancel_manually: "The order was closed by you manually."
    }

    // orderInfo: {
    //   id: 149,
    //   service: {
    //     id: 94,
    //     name: "HappyPancakeiin",
    //     icon:
    //       "https://api.smsplaza.io/media/service_icons/HappyPancakeiin.png"
    //   },
    //   number: "(+372) 53887555",
    //   status: "connected",
    //   error_reason: "",
    //   flag: "ee",
    //   created_at: "2020-01-05 14:35",
    //   timer: "271"
    // }
  }),

  filters: {
    not(value) {
      return value.split(" ")[0];
    }
  },

  computed: {
    ...mapGetters(["socetMessage"]),
    ...mapState(["socket"]),

    styleHeaderTimer() {
      return {
        opacity: 0
        // display: "none"
      };
    }
  },

  async created() {
    // this.step = "await_sms";
    // return;

    await this.handleGetOrderSMS();
    // this.step = "sms";
    if (this.orderInfo.status == "created") {
      this.step = "await_activate";
      this.isReadyToConnect = this.orderInfo.is_ready_to_connect;
      this.timer = this.orderInfo.timer;
      this.defaultTimer = 30 * 60;
      this.timerStart = "3";
      this.status = true;
    } else if (this.orderInfo.status == "connecting") {
      this.step = "activate";
      this.status = true;
    } else if (this.orderInfo.status == "connected") {
      if (this.messages.length > 0) {
        this.step = "sms";
      } else {
        this.step = "await_sms";
      }
      this.status = true;
      this.timer = this.orderInfo.timer;
      this.defaultTimer = 5 * 60;
      this.timerStart = "3";
    } else if (this.orderInfo.status == "completed") {
      this.step = "sms";
      this.error = this.orderInfo.error_reason;
      this.status = false;
      this.buyedSms = true;
    } else if (this.orderInfo.status == "canceled") {
      this.step = "error";
      this.error = this.orderInfo.error_reason;
      this.status = false;
    }
  },

  mounted() {
    // this.saveSocetMessage({
    //   type: "outcome",
    //   event: "sms.new",
    //   order_id: 129,
    //   sms: {
    //     id: 34,
    //     received_at: "2019-12-19 21:29",
    //     sms_text: "737*829*984*4",
    //     detected_service: null,
    //     was_bought: false,
    //     price: "3.00"
    //   }
    // });
    if (this.orderInfo == 90) {
      this.timerStart = "1";
    }
  },

  watch: {
    numberConnected(val) {
      if (val.includes(this.orderInfo.id)) {
        this.step = "await_sms";
        this.timer = 300;
        this.defaultTimer = 300;
        this.timerStart = "3";

        pushNotification(`Number ${this.orderInfo.number} success connected`);
      }
    },
    socetMessage() {
      if (this.socetMessage.order_id == this.orderInfo.id) {
        if (
          this.socetMessage.event === "order.completed" &&
          this.socetMessage.status === "canceled"
        ) {
          this.step = "error";
          this.error = this.socetMessage.error_reason;

          this.status = false;
        }

        if (this.socetMessage.event === "sms.new") {
          this.step = "sms";

          this.messages.unshift(this.socetMessage.sms);
        }

        if (this.socetMessage.event === "sms.new.wrong_service") {
          this.step = "sms";

          this.messages.unshift({
            received_at: this.socetMessage.received_at,
            // sms_text: `You got SMS from the "${
            //   this.socetMessage.correct_service.name
            // }", but you chose "${
            //   this.orderInfo.service.name
            // }". Create a new order with the correct service.`,
            detected_service: this.socetMessage.correct_service,
            was_bought: false,
            is_wrong_service: true
          });
        }

        if (this.socetMessage.event === "order.number_ready") {
          this.isReadyToConnect = true;
        }

        if (this.socetMessage.event === "order.number_busy") {
          this.isReadyToConnect = false;
          this.step = "await_activate";
        }
      }
    }
  },

  methods: {
    ...mapMutations({
      saveSocetMessage: `SOCKET_ONMESSAGE`
    }),

    doCopy: function(value) {
      let vm = this;
      this.$copyText(value).then(
        function(e) {
          vm.$toasted.show(value + " copied", {
            theme: "toasted-primary",
            position: "top-center",
            duration: 3000
          });
        },
        function(e) {
          vm.$toasted.show("Can not copy", {
            theme: "toasted-primary",
            position: "top-center",
            duration: 2000
          });
        }
      );
    },

    changeTimer(value) {
      this.timer = value;
    },

    async connectionNumber(data) {
      // this.timer = 1800;
      // this.timerStart = "3";
      // this.$emit("connect", data.id);

      let res = await OrdersService.connectNumber(data.id);

      if (res.generic_error == "Line is busy, please wait") {
        this.isReadyToConnect = false;
        this.step = "await_activate";

        this.$toasted.show(`Line is busy, please wait`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 2000,
          className: "danger-notification"
        });
      } else this.step = "activate";
    },

    async handleCancel() {
      this.isComfirmModalActive = true;
      //
    },

    handleBuy(index) {
      this.isCardModalActive = true;
      this.selectedSms = index;
    },

    handleSuccessBuy(data) {
      this.buyedSms = true;
      this.timer = 0;
      this.status = false;

      let messages = [...this.messages];
      messages[this.selectedSms] = data;

      this.messages = this.filteringMessages(messages);
      // this.messages[0] = {
      //   id: 218,
      //   sender: "37258663956",
      //   received_at: "21.01.2020 09:36",
      //   sms_text: "Google",
      //   detected_service: {
      //     id: 29,
      //     name: "Google",
      //     icon:
      //       "https://api.smsplaza.io/media/service_icons/Google_CPc34hN.png"
      //   },
      //   was_bought: true,
      //   price: "2.99"
      // };
    },

    handleSend(id) {
      // this.saveSocetMessage({
      //   type: "outcome",
      //   event: "sms.new.wrong_service",
      //   order_id: 157,
      //   correct_service: "Google"
      // });
      this.saveSocetMessage({
        type: "outcome",
        event: "sms.new.wrong_service",
        order_id: id,
        correct_service: "Google"
      });

      this.handleSuccessBuy(this.messages[0]);
    },

    async handleGetOrderSMS() {
      this.messages = await OrdersService.getOrderSMS(this.orderInfo.id);

      // if (response.length > 0) {
      //   this.step = "sms";

      //   this.messages = response;
      // } else {
      //   this.step = "await_sms";
      // }

      // this.status = true;
      // this.timer = this.orderInfo.timer;
      // this.defaultTimer = 5 * 60;
      // this.timerStart = "3";
    },

    filteringMessages(messages) {
      return messages.sort(
        (a, b) =>
          moment(b.received_at, "YYYY.MM.DD hh:mm:ss").unix() -
          moment(a.received_at, "YYYY.MM.DD hh:mm:ss").unix()
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.order-card {
  &__cancel-button {
    margin: 15px 0 17px;

    height: 90px;
    width: 90px;

    display: none;

    // border-radius: 50%;

    box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);
    border-radius: 50%;

    transition: all 0.5s;

    cursor: pointer;

    // box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }

  &__circle-timer {
    transition: all 0.5s;

    // border-radius: 50%;

    // box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }

  &__circle-timer-controller {
    &:hover {
      .order-card__circle-timer {
        display: none;

        transition: all 0.5s;
      }

      .order-card__cancel-button {
        display: block;

        transition: all 0.5s;
      }
    }
  }
}
</style>