<template>
  <b-modal :active.sync="isCardModalActive" :width="610" scroll="keep" @close="$emit('close')">
    <div class="order-modal order-modal_buy">
      <h1 class="order-modal__title">Cancel Order?</h1>

      <div class="order-modal__footer">
        <button class="order-modal__button" @click="handleCancel()">Yes</button>
        <button class="order-modal__button order-modal__button--close" @click="handleClose()">No</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { OrdersService } from "../../services/orders.services";

export default {
  props: {
    isCardModalActive: {
      type: Boolean,
      default: false
    },

    orderId: {
      type: Number,
      default: null
    }
  },

  data: () => ({}),

  methods: {
    async handleCancel() {
      await OrdersService.closeNumber(this.orderId);

      this.$emit("close");
    },

    handleClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.order-modal {
  &__footer {
    margin: 0 auto;
    width: 80%;

    display: flex;
  }

  &__button {
    margin: 0 1rem;

    &--close {
      background-color: #111014;

      transition: background-color 0.15s ease-in;

      &:hover {
        transition: background-color 0.15s ease-in;

        opacity: 0.7;
      }
    }
  }
}
</style>
